import * as React from 'react';
import './text-area.scss';

export interface ITextAreaProps {
    placeholder: string;
    label: string;
    width: string;
    height: string;
    value?: any;
    onChange: (value: string) => void
    isRequired?: boolean;
    isValid?: (valid: boolean, field: string) => void;
    showWarning?: boolean;
    maxLength?: number;
}

export function TextArea (props: ITextAreaProps) {
  const [inputValue, setInputValue] = React.useState<any>();

  const validate = () => {
    if(props.isRequired && (!inputValue && !props.value)) {
      return false;
    }
    return true;
  }
  
  React.useEffect(()=>{
    
    if(props.isValid) {
      if(validate() && (inputValue || props.value)) props.isValid(true, props.label);
      else props.isValid(false, props.label);
    }

  },[inputValue, props.value]);

  

  return (
    <div  style={{width:props.width}} className={'text-area-container'}>
      <div className={'text-input-label'}>
          {props.label} {props.isRequired && <span style={{color:'red'}}>*</span>}
      </div>
      <div className={'text-input-form'}>
            <textarea
                style={{height: props.height, border: `${props.showWarning && !validate() ? '0.01rem solid red' : ''}`}}
                placeholder={props.placeholder}
                onChange={(event) => {
                  props.onChange(event.target.value);
                  setInputValue(event.target.value);
                }}
                value={props.value}
                maxLength={props.maxLength}
            />
            <span style={{fontSize:'0.8em', marginTop:'-0.5rem', color:'gray'}}>{props.maxLength ? `Maximum ${props.maxLength} characters!` : ''}</span>
      </div>
    </div>
  );
}
