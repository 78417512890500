import { API_METHOD, MASTER_BASE_URL, FetchMaster, SLAVE_BASE_URL, MASTER } from "./FetchMaster";

export const updateCompany = (payload) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/company`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}

export const getCompany = (id) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/company/${id}`;
    return FetchMaster(url, API_METHOD.GET);
}