import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from '../Button/Button';
import './multi-text-input.scss';
export interface ITextInputProps {
  placeholder: string;
  label: string;
  width: string;
  value?: any;
  onChange?: (value: string) => void;
  disabled?: boolean;
  isRequired?: boolean;
  maxLength?: number;
  isValid?: (valid: boolean, field: string) => void;
  showWarning?: boolean;

}

const MultiTextInput = (props: ITextInputProps) => {
  const [inputValue, setInputValue] = useState<string>();
  const [vehicleNumbers, setVehicleNumbers] = useState([]);
  const validate = () => {
    if (props.isRequired && (!vehicleNumbers.length && !props.value)) {
      return false;
    }
    return true;
  }

  const addNumber = () => {
    if (!inputValue) return;
    let vehicles = [inputValue];
    if (props.value != null) {
      vehicles = [...props.value.split(','), ...vehicles]
    }
    let commaSeparatedNumbers = vehicles.toString();
    props.onChange(commaSeparatedNumbers);

    setInputValue("");
  }

  const removeNumber = (number) => {

    let vehicles = props.value.split(',').filter(num => num !== number)
    let commaSeparatedNumbers = vehicles.toString();

    props.onChange(commaSeparatedNumbers);

  }

  useEffect(() => {

    if (props.isValid) {
      if (validate() && (vehicleNumbers.length || props.value)) props.isValid(true, props.label);
      else props.isValid(false, props.label);
    }


  }, [inputValue, props.value]);


  // useEffect(()=>{
  //   props.onChange(vehicleNumbers.toString());

  // },[vehicleNumbers])

  // useEffect(()=>{
  //  if( props.value != null){
  //      setVehicleNumbers(props.value.split(','))
  //  } 
  // },[])

  return (
    <div style={{ width: props.width }} className={'multi-text-input-container'}>
      <div className={'text-input-label'}>
        {props.label} {props.isRequired && <span style={{ color: 'red' }}>*</span>}
      </div>
      <div className={'multi-text-text-input-form'} style={{ border: `${props.showWarning && !validate() ? '0.01rem solid red' : ''}` }}>
        <input
          type={'text'}
          placeholder={props.placeholder}
          onChange={(event) => {
            setInputValue(event.target.value.toUpperCase());
          }}
          value={inputValue}
          disabled={props.disabled}
        />
        <Button
          class='medium-btn-container'
          text={'Add'}
          width={'fit-content'}
          bgcolor={'#4D4D33'}
          textcolor={'white'}
          onClick={addNumber}
        />
      </div>
      <div className={'vehicle-number-chip-container'}>
        {
          props.value && props.value.split(',')?.map((number) => {
            return <div
              className={'vehicle-number-chip'}
            >
              {number}
              <img
                style={
                  {
                    height: '1.1rem',
                    marginLeft: '0.2rem',
                    cursor: 'pointer'
                  }}
                src={'/images/generic/cross.svg'}
                onClick={() => removeNumber(number)}
              />
            </div>
          })
        }
      </div>
    </div>
  );
}

export default MultiTextInput;