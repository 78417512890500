import * as React from 'react';
import './side-bar.scss';

import { useNavigate } from "react-router-dom";
import { sideNavBarRoutes } from '../Routes/SideBarRoutes';
import { useContext } from 'react';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import { useEffect } from 'react';
import { useState } from 'react';
import { MASTER_BASE_URL } from '../../API/FetchMaster';

export const SideBar = () => {
  const [selectedRoute, setSelectedRoute] = React.useState<any>("/");
  const {globalState:{permissions, company}} = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [logo, setLogo] = useState('');

  React.useEffect(() => {
    const currentPath = window.location.pathname;
    const initialSelectedRoute = sideNavBarRoutes.findIndex(
      (route) => route.routePath === currentPath
    );

    if (initialSelectedRoute !== -1) {
      setSelectedRoute(initialSelectedRoute);
    }
  }, []);

  const handleSideNavRoute = (path, index) => {
    navigate(path);
    setSelectedRoute(index);
  };

  useEffect(()=>{
    if(company && company.logo_url) {
      setLogo(company.logo_url);
    }
  },[company]);

  return (
    <div className={"sidenav__container"}>
      <div className={"sidenav__route-logo"}>
        <img
          className={"sidenav__route-logo-image"}
          src={ MASTER_BASE_URL + logo || 'images/side_bar/bytecoder.svg'}
          alt=""
        />
      </div>

      <div className={"sidenav__routes"}>
        {sideNavBarRoutes.map((navRoute, index) => {
        return [...permissions].includes(navRoute.accessName) && (
          <div
            key={index}
            className={`${"sidenav__routes-link"} ${
              selectedRoute === index ? "selected-route" : ""
            }`}
            onClick={() => handleSideNavRoute(navRoute.routePath, index)}
          >
            <div
              className={`${"sidenav__route-border"} ${
                selectedRoute === index ? "selected-border" : ""
              }`}
            ></div>
            <img
              className={"sidenav__route-image"}
              src={navRoute.routeImage}
              alt=""
            />
            <p className={"sidenav__route-name"}>{navRoute.routeName}</p>
          </div>)
        })}
      </div>
    </div>
  );
};
