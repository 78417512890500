import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../Generic/Button/Button';
import TextInput from '../../../Generic/TextInput/TextInput';
import './add-access.scss';
import { toast } from 'sonner';
import Dropdown from '../../../Generic/Dropdown/Dropdown';
import { addGateCred, addNewCred } from '../../../../API/Gates';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { Switch } from 'antd';
import { getUser, updateUser } from '../../../../API/Configs';
import {  useNavigate, useParams } from 'react-router-dom';

export interface IAddNewCredentialProps {
}

export const EditUser = (props: IAddNewCredentialProps) => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user.application_configs);
  const { id } = useParams();
const navigate = useNavigate()
  const form = {
    username: '',
    password: '',
    role: '',
    is_enabled: true,
  };
  const [formData, setFormData] = React.useState(form);
  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {

    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const submitForm = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }

      const payload = {
        username: formData.username,
        password: formData.password,
        role: formData.role,
        company_id: user.company_id,
        is_notification_enabled: formData.is_enabled

      }
      const response = await updateUser(payload);

      if (response && response.statusCode === 200) {
        toast.success(response.message)
        setFormData(form);
        navigate('/configs')
      }
      else throw new Error(response.message);

    }
    catch (err) {
      toast.error(err.message)
    }
  }


  React.useEffect(() => {
    getUser(id).then(res => setFormData({
      username: res.response.username,
      password: res.response.password,
      role: res.response.role,
      is_enabled: res.response.is_notification_enabled
    }))
  }, []);


  return (
    <div className={'new-access-form-container'}>
      <div className={'title-left'}>
        Edit Credential
      </div>
      <div style={{ marginTop: '2rem', display: 'flex', gap: '2rem' }}>
      <TextInput
          label={'User role'}
          placeholder={'Enter username'}
          width={'30%'}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          onChange={(value) => setFormData({ ...formData, username: value })}
          value={formData.role}
          disabled
        />
       

      </div>
      <div style={{ marginTop: '2rem', display: 'flex', gap: '2rem' }}>
        <TextInput
          label={'User name'}
          placeholder={'Enter username'}
          width={'30%'}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          onChange={(value) => setFormData({ ...formData, username: value })}
          value={formData.username}
          disabled
        />
        <TextInput
          label={'Password'}
          placeholder={'Enter password'}
          width={'30%'}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          onChange={(value) => setFormData({ ...formData, password: value })}
          value={formData.password}
        />

      </div>
      <div>
        <Switch
          style={{ margin: '1rem' }}
          value={formData.is_enabled}
          onChange={(value) => {
            setFormData({
              ...formData,
              is_enabled: value
            });
          }}
        />
        Enable Notifications
      </div>
      <div style={{ justifyContent: 'center', display: 'flex', margin: '2rem 2rem 0 30rem' }}>
        <Button
          class='btn-container'
          bgcolor={'#4D4D33'}
          textcolor={'white'}
          text={'Update User'}
          width={'fit-content'}
          onClick={submitForm}
        />
      </div>
    </div>
  );
}
