import * as React from 'react';
import { SearchBar } from '../../Generic/SearchBar/SearchBar';
import { GenericTable } from '../../Generic/Table';
import { ProfileDetailsSideCard } from './ProfileDetails/ProfileDetailsSideCard';
import './visitors-list.scss';
import { ROUTES } from '../../Routes/SideBarRoutes';
import { getAllVisitors } from '../../../API/Visitors';
import { useEffect } from 'react';
import { Filters } from '../../Generic/Filters/Filters';
import { Button } from '../../Generic/Button/Button';
import autoTable from 'jspdf-autotable';
import { jsPDF } from "jspdf";
import { useRef } from 'react';
import { useState } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';

export interface IVisitorsProfilesProps {
  showEntryForm: () => void;
  showExitForm: () => void;
}

export const VisitorsList = (props: IVisitorsProfilesProps) => {
  const outRef = useRef(null);
  const [total_visitors, setTotalVisitors] = React.useState(0)
  const [pageVariables, setPageVariables] = React.useState({
    selectedProfile: null,
    tableData: [],
    loading: false
  });
  const [openPop, setOpenPop] = useState(false);
  const { globalState: { user, permissions } } = React.useContext(GlobalStateContext);

  const [isAutoPullEnabled, setIsAutoPullEnabled] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [filters, setFilters] = useState(null);
  const [query, setQuery] = useState(null);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };


  const tableDemoData = {
    columnDetails: [
      {
        title: 'Name',
        sortable: true,
        bold: true,
        key: "fullname",
        // image_key: 'profile_image'
      },
      {
        title: 'Phone no./ID no.',
        key: "phone_number"
      },
      {

        title: 'Gender',
        key: "gender"
      },
      {
        title: 'Unit/Org',
        key: "unit"
      },
      {
        title: 'Category',
        key: "category"
      },
      // {
      //   title: 'Vehicle Number',
      //   key: "vehicle_number"
      // },
      {
        title: 'Action',
        key: "View_details",
        route: ROUTES.visitorDetails,
        route_key: 'visitor_id'
      },
    ]
  };

  const fetchVisitorsList = async (filters = null, page = 1, query = null) => {
    let options = {
    }
    if (filters) {
      options = {
        ...options,
        start_date: filters.start_date,
        end_date: filters.end_date,
        unit: filters.unit,
        category: filters.category,
      }
    }

    options = {
      ...options,
      page: page,
      limit: limit,
      query: query
    }
    // setPageVariables({ ...pageVariables, loading: true });
    try {
      const response = await getAllVisitors(options);
     
      // prepare rows
      setTotalVisitors(response.response.total_visitors)
      setTotalItems(response.response.totalCounts)
      let dataRows = [];
      response.response.visitorDtos?.forEach(data => {
        const { visitor_id, fullname, phone_number, gender, unit, category, vehicle_number, is_primary, profile_image, id_card, created_at, is_active } = data;

        dataRows = [...dataRows,
        {
          "visitor_id": visitor_id,
          "fullname": fullname,
          "phone_number": phone_number,
          "gender": gender,
          "unit": unit,
          "category": category,
          "vehicle_number": vehicle_number,
          "View_details": 'View Details',
          "is_primary": is_primary,
          "profile_image": profile_image,
          "id_card": id_card,
          "created_at": created_at,
          'is_active': is_active
        }
        ];

      })
      
        setPageVariables({ ...pageVariables, tableData: dataRows, loading: false });
    }
    catch (err) {
      setPageVariables({ ...pageVariables, loading: false });
    }
  }

  const exportPDF = async () => {

    const doc = new jsPDF()
    let tableBodyData = [];

    await pageVariables.tableData.forEach(data => {
      let tableRow = [];
      tableDemoData.columnDetails.forEach(column => {
        if (column.key != "View_details") {
          console.log(column.key)
          tableRow.push(data[column.key]);

        }
      });
      tableBodyData.push(tableRow);
    });

    autoTable(doc, {
      head: [tableDemoData.columnDetails.map(data => {
        if (data.title != "Action") {
          return data.title
        }
      })],
      body: tableBodyData
    });

    doc.save('download' + '.pdf')
  }

  const findOutsideClick = (event) => {
    if (outRef.current && !outRef.current.contains(event.target)) {
      setOpenPop(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", findOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", findOutsideClick);
    };
  }, [outRef]);

  useEffect(() => {
    fetchVisitorsList(filters, page, query);
  }, []);


  useEffect(() => {
    if ((query == null || query == '') && page == 1 && filters == null) {
      setIsAutoPullEnabled(true)
    } else {
      setIsAutoPullEnabled(false)

    }
    fetchVisitorsList(filters, page, query);

  }, [page, limit, query, filters]);


  useEffect(() => {
    let intervalId;
    if (isAutoPullEnabled && !openPop) {
      intervalId = setInterval(fetchVisitorsList, 20000);
    }
    return () => clearInterval(intervalId);
  }, [isAutoPullEnabled, openPop]);

  return (
    <div className={'visitors-list-container'} >
      <div className={'visitors-list-header'}>
        <div className={'visitors-list-header-left'}>
          <div className={'title'}>
            Visitors Profiles
          </div>
          <div className={'title'}>
            <span style={{ margin: '0 1em' }}>

              Total Registered : {total_visitors}
            </span>
          </div>

        </div>
        <div style={{ display: 'flex', marginRight: '2rem' }}>
          <div>
            <SearchBar
              placeholder={'Search visitors by name or phone number'}
              width={'20rem'}
              onSearch={query => setQuery(query)}
            />
          </div>
          <Filters
            unitFilter={false}
            categoryFilter={true}
            gateFilter={false}
            dateFilter={false}
            apply={(filters) => setFilters(filters)}
          />
        </div>
      </div>
      {[...permissions].includes("ANALYTICS_REPORTS") &&

        <div>
          <Button
            class='medium-btn-container'
            bgcolor={'#7B0323'}
            text={'Download Report'}
            textcolor={'white'}
            width={'10rem'}
            onClick={exportPDF}
          />
        </div>
      }
      <GenericTable

        columnDetails={tableDemoData.columnDetails}
        dataRows={pageVariables.tableData}
        selectedRow={(value) => {
          setPageVariables(
            {
              ...pageVariables,
              selectedProfile: value,
            });
          setOpenPop(true);
        }
        }
        loading={pageVariables.loading}
        id="visitors-list-table"
        pagination={true}
        currentPage={page}
        totalItems={totalItems}
        initialLimit={limit}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      <div ref={outRef}>
        <ProfileDetailsSideCard
          open={openPop}
          close={() => setOpenPop(false)}
          profile={pageVariables.selectedProfile}
          makeEntry={() => { props.showEntryForm() }}
          makeExit={() => { props.showExitForm() }}
        />
      </div>
    </div>
  );
}