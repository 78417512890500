import { LOCAL_STORAGE_KEYS } from "../components/Login/Login";
import { API_METHOD, MASTER_BASE_URL, FetchMaster, MASTER, SLAVE_BASE_URL } from "./FetchMaster";

export const login = (payload) => {
    let url = `${MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL}/auth/login`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}



export const authorise_token = () => {
    let url = `${MASTER_BASE_URL }/token`;
    return fetch(url, {
        method: API_METHOD.GET,
        headers: {
            Accept: 'application/json',
            token: `${localStorage.getItem(LOCAL_STORAGE_KEYS.stored_token)}`
          },
    }).then((response) => response.json())

}
