export const CameraModel = [
    "AM",
    "MST"
];

export const CameraAlgorithm = [
    "SS1",
    "MAM"
]

export const algo_settings_columns = [
    "Detection",
    "Count",
    "Time to Trigger",
    "Time to Cool",
    "Alerts/Notification",
    "Gates/Control Room"
  ];


export const algo_settings = {
    Weapon:[
        {
        name: 'Weapon',
        type: 'checkbox',
        fieldId: 'weapon'
        },
        {
        name: 'Count',
        type: 'counter',
        fieldId: 'count'
        },
        {
        name: 'Time to Trigger',
        type: 'time',
        fieldId: 'time_to_trigger'
        },
        {
        name: 'Time to Cool',
        type: 'time',
        fieldId: 'time_to_cool'
        },
        {
        name: 'Alerts/Notification',
        type: 'text',
        fieldId: 'alert_or_notification'
        },
        {
        name: 'Gates/Control Room',
        type: 'multiSelect',
        fieldId: 'gates'
        },
    ],
    Human:[
        {
        name: 'Human',
        type: 'checkbox',
        fieldId: 'weapon'
        },
        {
        name: 'Count',
        type: 'counter',
        fieldId: 'count'
        },
        {
        name: 'Time to Trigger',
        type: 'time',
        fieldId: 'time_to_trigger'
        },
        {
        name: 'Time to Cool',
        type: 'time',
        fieldId: 'time_to_cool'
        },
        {
        name: 'Alerts/Notification',
        type: 'text',
        fieldId: 'alert_or_notification'
        },
        {
        name: 'Gates/Control Room',
        type: 'multiSelect',
        fieldId: 'gates'
        },
    ],
    Bike:[
        {
        name: 'Bike',
        type: 'checkbox',
        fieldId: 'weapon'
        },
        {
        name: 'Count',
        type: 'counter',
        fieldId: 'count'
        },
        {
        name: 'Time to Trigger',
        type: 'time',
        fieldId: 'time_to_trigger'
        },
        {
        name: 'Time to Cool',
        type: 'time',
        fieldId: 'time_to_cool'
        },
        {
        name: 'Alerts/Notification',
        type: 'text',
        fieldId: 'alert_or_notification'
        },
        {
        name: 'Gates/Control Room',
        type: 'multiSelect',
        fieldId: 'gates'
        },
    ],
    Car:[
        {
        name: 'Car',
        type: 'checkbox',
        fieldId: 'weapon'
        },
        {
        name: 'Count',
        type: 'counter',
        fieldId: 'count'
        },
        {
        name: 'Time to Trigger',
        type: 'time',
        fieldId: 'time_to_trigger'
        },
        {
        name: 'Time to Cool',
        type: 'time',
        fieldId: 'time_to_cool'
        },
        {
        name: 'Alerts/Notification',
        type: 'text',
        fieldId: 'alert_or_notification'
        },
        {
        name: 'Gates/Control Room',
        type: 'multiSelect',
        fieldId: 'gates'
        },
    ],
    Truck_Bus:[
        {
        name: 'Truck/Bus',
        type: 'checkbox',
        fieldId: 'weapon'
        },
        {
        name: 'Count',
        type: 'counter',
        fieldId: 'count'
        },
        {
        name: 'Time to Trigger',
        type: 'time',
        fieldId: 'time_to_trigger'
        },
        {
        name: 'Time to Cool',
        type: 'time',
        fieldId: 'time_to_cool'
        },
        {
        name: 'Alerts/Notification',
        type: 'text',
        fieldId: 'alert_or_notification'
        },
        {
        name: 'Gates/Control Room',
        type: 'multiSelect',
        fieldId: 'gates'
        },
    ],
    
}