import * as React from 'react';
import './generic-button.scss';

export interface IButtonProps {
    bgcolor: string;
    textcolor: string;
    width: string;
    text: string;
    class: string ;
    onClick?: any;
    id?: string;
}

export const Button = (props: IButtonProps) => {
  return (
    <div style={{backgroundColor: props.bgcolor, width: props.width, color: props.textcolor }} 
        className={props.class}
        onClick={props.onClick}
        id={props.id}
    >
        {props.text}
    </div>
  );
}