
import './company-component.scss'
export default function CompanyComponent() {

    return (
        <div className='company-component'>

            <div>
                <img src="/images/company-logo.png" />
            </div>
            {/* <div>
                MADE IN INDIA
            </div> */}


        </div>
    )
}