import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs'

interface IProps  {
    setValue?: (value: string)=>void;
    label?: string;
    value?:string
}

export default function BasicTimePicker(props: IProps) {
    const [hours, minutes] = props.value? props.value.split(':') : "00:00".split(":");
    const dateStr = `2000-01-01T${hours}:${minutes}:00`; // Assuming the year, month, and day are irrelevant for time-only representation
    const parsedTime = dayjs(dateStr);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']} >
                <TimePicker value={parsedTime} label={props.label} ampm={false} onChange={(newValue) => props.setValue(newValue.format("HH:mm"))}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}

export const  BasicTimePickerMMSS = (props: IProps) => {


    const [minutes, seconds] = props.value? props.value.split(':') : "00:00".split(':');
    const dateStr = `2000-01-01T00:${minutes}:${seconds}`; // Assuming the year, month, and day are irrelevant for time-only representation
    const parsedTime = dayjs(dateStr);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']} >
                <TimePicker value={parsedTime} label={props.label} views={['minutes', 'seconds']} format="mm:ss" onChange={(newValue) => props.setValue(newValue.format("mm:ss"))}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}