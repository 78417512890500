import { IGlobalState } from "./GlobalContextProvider";

interface Action {
    type: string;
    payload: string;
    booleanPayload: true | false;
}


export const ACTIONS = {
    setLoggedIn: 'setLoggedIn',
    setEntryExitVisitor: 'setEntryExitVisitor',
    setLoggedInUser: 'setLoggedInUser',
    setPermissions: 'setPermissions',
    setGates: 'setGates',
    setCompany: 'setCompany',
    setSyncNotifications: 'setSyncNotifications',
    pushNotification: 'pushNotification',
    popNotification: 'popNotification',
  }; 

export const globalStateReducer = (pState: IGlobalState, action:Action) => {
   switch (action.type) {
    case ACTIONS.setLoggedIn:
      console.log("ACTIONS.setLoggedIn");
      
      return { ...pState, loggedIn: action.booleanPayload};
    case ACTIONS.setEntryExitVisitor:
      return { ...pState, entryExitVisitor: action.payload};
    case ACTIONS.setLoggedInUser:
      return { ...pState, user: action.payload};
    case ACTIONS.setPermissions:
      return { ...pState, permissions: action.payload};
    case ACTIONS.setGates:
      return { ...pState, gates: action.payload};
    case ACTIONS.setCompany:
      return { ...pState, company: action.payload};
    case ACTIONS.setSyncNotifications:
      return { ...pState, syncNotifications: action.booleanPayload};
    case ACTIONS.pushNotification:
      return { ...pState, notifications: action.payload};
    case ACTIONS.popNotification:
      return { ...pState, popNotificationAlert: action.booleanPayload};
    default:
       return {...pState};
   }
}