import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../Generic/Button/Button';
import { GenericTable } from '../../Generic/Table';
import { ROUTES } from '../../Routes/SideBarRoutes';
import './gate-details.scss';
import { disableGate, getGateById } from '../../../API/Gates';
import { useState } from 'react';
import { getVisitorActivitiesByGate } from '../../../API/Visitors';
import { ActivityDetailsSideCard } from '../../VisitorManagement/VisitorsActivities/ActivityDetailsSideCard/ActivityDetailsSideCard';
import { Filters } from '../../Generic/Filters/Filters';
import { DeleteSideCard } from './DeletePopUp/DeleteSideCard';
import { toast } from 'sonner';
import { MASTER_BASE_URL } from '../../../API/FetchMaster';

export interface IGateDetailsProps {
}

export function GateDetails(props: IGateDetailsProps) {

  const navigate = useNavigate();
  const { gate_number } = useParams();
  const [pageVars, setPageVars] = useState({
    created_at: '',
    gate_number: '',
    location: '',
    name: '',
    permissions: '',
    photo: '',
    commander: '',
    gate_type: '',
    coordinates: '',
    unit: '',
    allowed_categories: '',
    is_active: false,

    selectedActivity: null,
    openPopUp: false,
    tableData: [],
    showFilters: false,
    openDeleteCard: false
  });

  const [loaders, setLoaders] = useState({
    updatingGate: false
  });

  const [tableVars, setTableVars] = useState({
    tableData: [],
    totalIn: 0,
    totalOut: 0,
    loading: false
  });


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const handlePageChange = (newPage) => {
    setPage(newPage);

  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };


  const tableDemoData = {


    columnDetails: [
      {
        title: 'Visitor name',
        sortable: true,
        bold: true,
        key: "fullname"
      },
      {
        title: 'Passengers',
        key: "no_of_passengers"
      },
      {
        title: 'Relation with passengers',
        key: "relation_with_passengers"
      },
      {
        title: 'Timestamp',
        key: "timestamp"
      },
      {
        title: 'Activity Type',
        inOut: true,
        key: "activity"
      },
      {
        title: 'Category',
        key: "category"
      },
      {
        title: 'Approved By',
        key: "approved_by"
      },
    ],
  }
  const navigateBack = () => {
    navigate(ROUTES.gates)
  }
  const fetchGateActivities = async (page, filters, query?) => {
    try {
      setTableVars({ ...tableVars, loading: true });
      let options = {

      }

      if (filters) {
        options = {
          ...options,
          start_date: filters.start_date,
          end_date: filters.end_date,
          unit: filters.unit,
          category: filters.category,
          activity: filters.activity
        }
      }
      options = {
        ...options,
        page: page,
        gate_number: gate_number,
        query: query
      }
      const response = await getVisitorActivitiesByGate(options);
      if (response && response.statusCode === 200) {
        let dataRows = [];

        response.response.visitorDtos.forEach(visit => {
          const { visitor_id, fullname, profile_image, category, activity, vehicle_number, approved_by, relation_with_passengers,
            no_of_passengers, timestamp
          } = visit;

          dataRows = [...dataRows, {
            "visitor_id": visitor_id,
            "fullname": fullname,
            "profile_image": profile_image,
            "category": category,
            "gate_number": gate_number,
            "activity": activity,

            "approved_by": approved_by,
            "vehicle_number": vehicle_number,
            "relation_with_passengers": relation_with_passengers,
            "no_of_passengers": no_of_passengers,
            "timestamp": timestamp,
          }]
        });

        setTableVars({ ...tableVars, tableData: dataRows, totalIn: response.response.totalIn, totalOut: response.response.totalOut, loading: false });
      }
      else throw new Error("Error");

    }
    catch (err) {
      setTableVars({ ...tableVars, loading: false });
    }
  }
  const fetchGateDetails = async () => {
    try {
      const response = await getGateById(gate_number);

      if (response && response.statusCode === 200) {
        const {
          location,
          name,
          photo,
          commander,
          coordinates,
          gate_type,
          unit,
          allowed_categories,
          created_at,
          is_active
        } = response.response;
        setPageVars({
          ...pageVars,
          name: name,
          location: location,
          photo: photo,
          commander: commander,
          coordinates: coordinates,
          gate_type: gate_type,
          unit: unit,
          allowed_categories: allowed_categories,
          created_at: created_at,
          gate_number: gate_number,
          is_active: is_active,
          openDeleteCard: false
        });
      }
      else throw new Error("Error");

    }
    catch (err) {
      toast.error("Error: Failed to fetch gate details!")
    }
  }

  const blockGate = async () => {
    let is_active = pageVars.is_active;
    try {
      setLoaders({ ...loaders, updatingGate: true });
      const response = await disableGate(gate_number, [null, true].includes(is_active));
      if (response && response.statusCode === 200) {
        toast.success(`Success: gate ${[null, true].includes(is_active) ? 'disabled' : 'enabled'}!`);
        setLoaders({ ...loaders, updatingGate: false });
        setPageVars({ ...pageVars, openDeleteCard: false });
        fetchGateDetails();
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error(`Error: Failed to ${is_active ? 'disabled' : 'enabled'} gate!`)
      setLoaders({ ...loaders, updatingGate: false });
    }
  }

  useEffect(() => {
    setTableVars({ ...tableVars, loading: true });
    fetchGateDetails();
    fetchGateActivities(page, null);
  }, []);

  useEffect(() => {

    fetchGateActivities(page, null);
  }, []);

  return (
    <div className={'gate-details-container'}>
      <div className={'gate-details-left-panel'}>
        <div className={'visitor-details-left-panel-back-btn'}>
          <Button
            class='medium-btn-container'
            text={'< Back to Gate list'}
            textcolor={'dark-green'}
            bgcolor={'#09784433'}
            width={'fit-content'}
            onClick={navigateBack}
          />
        </div>
        <div className={'profile-image-name-section'}>
          <div>
            <div className={'profile-image'}>
              <img
                style={{ height: '10rem', width: '10rem' }}
                src={pageVars.photo ? (MASTER_BASE_URL + pageVars.photo) : '/images/side_bar/gateblack.svg'}

              />
            </div>
          </div>
          <div>
            <div className={'visitor-name'} style={{ textAlign: 'center' }}>
              Gate - {gate_number}
            </div>
            <div className={'visitor-status'}>
              <span className={[null, true].includes(pageVars.is_active) ? 'status-active' : 'status-blocked'}>
                {[null, true].includes(pageVars.is_active) ? 'Active' : 'Disabled'}
              </span>
            </div>
          </div>
        </div>
        <div className={'personal-details-section'}>
          <div className={'personal-details-section-header'}>
            <div>Gate Details</div>
            <div className={'personal-details-section-header-btns'}>

              <Button
                class='medium-btn-container'
                text={'Edit'}
                textcolor={'white'}
                bgcolor={'#4D4D33'}
                width={'fil-content'}
                onClick={() => navigate(ROUTES.updateGate + '/' + gate_number)}
              />

              {[null, true].includes(pageVars.is_active) ?
                <Button
                  class='medium-btn-container'
                  text={'Disable'}
                  textcolor={'white'}
                  bgcolor={'#7B0323'}
                  width={'fil-content'}
                  onClick={() => setPageVars({ ...pageVars, openDeleteCard: !pageVars.openDeleteCard })}
                /> :
                <Button
                  class='medium-btn-container'
                  text={'Enable'}
                  textcolor={'white'}
                  bgcolor={'#4D4D33'}
                  width={'fil-content'}
                  onClick={() => setPageVars({ ...pageVars, openDeleteCard: !pageVars.openDeleteCard })}
                />
              }
            </div>
          </div>
          <div className='personal-details'>
            <div className='personal-details-div'>
              <span>Gate Name</span> : <span>{pageVars.name}</span>
            </div>
            <div className='personal-details-div'>
              <span>Location</span> : <span>{pageVars.location || 'N/A'}</span>
            </div>
            <div className='personal-details-div'>
              <span>Coordinates</span> : <span>{pageVars.coordinates || 'N/A'}</span>
            </div>
            <div className='personal-details-div'>
              <span>Unit</span> : <span>{pageVars.unit || 'N/A'}</span>
            </div>
            <div className='personal-details-div'>
              <span>Allowed Categories</span> : <span>{pageVars.allowed_categories || 'N/A'}</span>
            </div>
            <div className='personal-details-div'>
              <span>Commander</span> : <span>{pageVars.commander || 'N/A'}</span>
            </div>
            <div className='personal-details-div'>
              <span>Type</span> : <span>{pageVars.gate_type || 'N/A'}</span>
            </div>
            <div className='personal-details-div'>
              <span>Created On</span> : <span>{pageVars.created_at || 'N/A'}</span>
            </div>
          </div>
        </div>
        {/* <div className={'personal-details-section'}>
            <div className={'personal-details-section-header'}>
              <div>Permissions</div>
            </div>
            <div className='gate-details'>
              <div className='gate-details-div'>
                <span>{'>'}</span><span>{`'permission'`}</span>
              </div>
              <div className='gate-details-div'>
                <span>{'>'}</span><span>{`'permission'`}</span>
              </div>
              <div className='gate-details-div'>
                <span>{'>'}</span><span>{`'permission'`}</span>
              </div>
            </div>
          </div> */}

      </div>
      <div className={'overview-container'}>
        <div className={'overview-dashboard'}>
          <div className={'overview-dashboard-left'}>
            <div style={{ fontWeight: 'bold', fontSize: '1.5rem', height: 'fit-content', width: 'fit-content' }}>Overview</div>
            {/* <div style={{fontSize:'0.9rem', color:'gray', height:'fit-content', width:'fit-content'}}>
                Overview of the gates
              </div> */}
          </div>
          <div className={'overview-dashboard-gate-stat'}>
            <p>Total In</p>
            <p></p>
            <p>{tableVars.totalIn}</p>
          </div>
          <div className={'overview-dashboard-gate-stat'}>
            <p>Total Out</p>
            <p></p>
            <p>{tableVars.totalOut}</p>
          </div>
        </div>
        <div className={'activities-list'}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Activities at Gate</div>
            <div>
              <div style={{ display: 'flex' }}>
                <Filters
                  unitFilter={false}
                  categoryFilter={true}
                  activityFilter={true}
                  dateFilter={true}
                  apply={(filters)=>fetchGateActivities(page, filters)}
                />
              </div>
            </div>
          </div>
          <div className={'activity-card'}>
            <GenericTable
              columnDetails={tableDemoData.columnDetails}
              dataRows={tableVars.tableData}
              selectedRow={(row) => setPageVars({ ...pageVars, selectedActivity: row, openPopUp: true })}
              loading={tableVars.loading}

              totalItems={totalItems}
              initialLimit={limit}
              onPageChange={handlePageChange}
              onLimitChange={handleLimitChange}
            />
          </div>
        </div>
      </div>
      {pageVars.selectedActivity && <ActivityDetailsSideCard
        open={pageVars.openPopUp}
        activity={pageVars.selectedActivity}
        close={() => setPageVars({ ...pageVars, openPopUp: false })}
      />}
      {
        <DeleteSideCard
          block={[null, true].includes(pageVars.is_active)}
          open={pageVars.openDeleteCard}
          close={() => setPageVars({ ...pageVars, openDeleteCard: false })}
          confirm={blockGate}
          gate={pageVars}
          updating={loaders.updatingGate}
        />
      }
    </div>
  );
}
