import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../Generic/Dropdown/Dropdown';
import '../../Generic/TextInput/TextInput';
import TextInput from '../../Generic/TextInput/TextInput';
import './edit-camera.scss';
import { fetchCameraById, updateCameraById } from '../../../API/Camera';
import { toast } from 'sonner';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { TextArea } from '../../Generic/TextArea/TextArea';
import { LatLong, Resolution } from '../../Generic/LatLong/LatLong';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../Routes/SideBarRoutes';
import BasicTimePicker, { BasicTimePickerMMSS } from '../../Generic/TimePicker/TimerPicker';
import { Switch, TimePicker } from 'antd';
import { algo_settings, algo_settings_columns } from '../../../Constants/Camera';


export const IpV4RegEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const IpV6RegEx = /((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}/;

export const EditCamera = () => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);
  const { camera_id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: null,
    model: null,
    ai_algorithm: null,
    ip_address: null,
    streaming_url: null,
    unit: null,
    location: null,
    coordinates: '',
    resolution: '',
    message: null,
    gate_numbers: null,
  });
  const [showSettings, setShowSettings] = useState(false);
  const [settingsFormData, setSettingsFormData] = useState({

  });

  const [settingsSwitchData, setSettingsSwitchData] = useState({
    virtual_fence: false,
    is_enabled: false
  });

  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const handleClick = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }
      let algo_rules = [];
      Object.keys(settingsFormData).forEach(key => {
        let gates = null;
        if(settingsFormData[key] && settingsFormData[key].gates)
          gates = settingsFormData[key].gates?.split(",");

        algo_rules.push({
          algo_name: key,
          ...settingsFormData[key],
          gates: gates ? gates : undefined,
        })
      });

      let algo_settings = {
        algo_rules: algo_rules,
        ...settingsSwitchData
      }
      
      const payload = { ...formData, algo_settings: algo_settings };
      console.log(payload)
      const response = await updateCameraById(payload, camera_id);
      if (response && response.statusCode === 200) {
        toast.success("Success: Camera updated!");
        navigate(ROUTES.cameras);
      }
      else throw new Error("Error");
    }
    catch (err) {
      toast.error("Error: failed to update camera!");
    }
  }


  const getGates = () => {
    let gateNumbers = [];
    gates && gates.forEach(gate => {
      gateNumbers.push(gate.gate_number);
    });

    return gateNumbers;
  }

  const  getCameraByID = async () => {
    try {
      const response = await fetchCameraById({}, camera_id);
      if(response && response.statusCode === 200) {
        let { name, model, ai_algorithm, ip_address, streaming_url, unit, gate_numbers, message, location, status, coordinates, created_at, roi, algo_settings, resolution  } = response.response.cameraDto;
        setFormData({
          ...formData,
          name: name,
          model: model,
          ai_algorithm: ai_algorithm,
          ip_address: ip_address,
          streaming_url: streaming_url,
          unit: unit,
          location: location,
          message: message,
          gate_numbers: gate_numbers ? gate_numbers.toString() : '',
          coordinates: coordinates,
          resolution: resolution
        })
        
        if(algo_settings) {

          if(algo_settings.algo_rules) {

            let temp = {};

            algo_settings.algo_rules.forEach(algo_rule => {
              temp = {
                ...temp,
                [algo_rule.algo_name]: {
                  ...algo_rule,
                  gates: algo_rule?.gates ? algo_rule?.gates.join(',') : undefined
                }
              }
            })
            console.log(temp)
            setSettingsFormData({
              ...temp
            });
          }

          setSettingsSwitchData({
            ...settingsSwitchData,
            virtual_fence: algo_settings?.virtual_fence ? true : false,
            is_enabled: algo_settings?.is_enabled ? true : false
          })

        }
      }
    }
    catch(err) {
      toast.error("Error: failed to fetch camera!");
    }
  }

  const setSettingsValue = (value, field, algo_name) => {
    if(settingsFormData[algo_name]) {
      let previousSetting = {...settingsFormData[algo_name]};
      let newSetting = {
        ...previousSetting,
        [field]: value
      }
      setSettingsFormData({
        ...settingsFormData,
        [algo_name]: newSetting
      });
    }
    else return;
  }

  const getInputFields = (setting: {name: string, type: string, fieldId: string}, algo_name) => {

    switch (setting.type) {
      case 'checkbox':
        return (
          <div style={{display:'flex'}}>
            <input
              type={'checkbox'}
              style={{height:'1.5rem', width:'1.5rem', cursor:'pointer'}}
              checked={settingsFormData[algo_name]}
              onChange={(event)=>{

                if(!settingsFormData[algo_name]) {
                  setSettingsFormData({
                    ...settingsFormData,
                    [algo_name]: {}
                  })
                }
                else {
                  let settingsFormDataClone =  {...settingsFormData};
                  delete settingsFormDataClone[algo_name];

                  setSettingsFormData({
                    ...settingsFormDataClone
                  })
                }
              }}
            />
            <div style={{padding:'0.4rem'}}>
              {setting.name}
            </div>
          </div>
        );
      case 'counter':
        return (
          <div>
            <input 
              style={
                {
                  width:'4rem', 
                  height:'3rem', 
                  fontSize:'1.4rem', 
                  border:'0.01rem solid silver', 
                  borderRadius:'0.3rem'
                }
              } 
              type={'number'}  
              placeholder={'1'}
              onChange={(event) => {
                if(Number.parseInt(event.target.value) >=1) {
                  setSettingsValue(event.target.value, setting.fieldId, algo_name);
                }
              }}
              value={settingsFormData?.[algo_name]?.[setting.fieldId] || 1}
            />
          </div>
        );
      case 'time':
        return (
          <div>
            <BasicTimePickerMMSS
              setValue={(value)=>{
                setSettingsValue(value, setting.fieldId, algo_name);
              }}
              label={''}
              value={settingsFormData?.[algo_name]?.[setting.fieldId]}
            />
          </div>
        );
      case 'text':
        return (
          <div>
            <TextInput
              label={''}
              placeholder={'Enter alert/message'}
              width={'95%'}
              onChange={(value) => {
                setSettingsValue(value, setting.fieldId, algo_name);
              }}
              value={settingsFormData?.[algo_name]?.[setting.fieldId] || ''}
              maxLength={100}
              /> 
          </div>
        );
      case 'multiSelect':
        return (
          <Dropdown
            label={''}
            placeholder={'Select gate'}
            width={'90%'}
            options={getGates()}
            onSelect={(value) => {
              setSettingsValue(value, setting.fieldId, algo_name);
            }}
            selection={settingsFormData?.[algo_name]?.[setting.fieldId] || ''}
            variant={DropDownVariant.multiValue}
          />
        )
      
      default:
        return 'null';
    }

  }

  useEffect(()=>{
    getCameraByID();
  },[]);

    return (
      <div className={'edit-camera-form-container'}>
        <div className={'add-camera-form-title'}>
          Edit Camera
        </div>
        { !showSettings ?
        <div>
        <div className={'add-camera-form-row-1'}>
          <TextInput
            label={'Camera Name/Number'}
            placeholder={'Enter camera name/number'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, name: value })}
            value={formData.name}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

          <TextInput
            label={'Model/Specs'}
            placeholder={'Enter camera specs'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, model: value })}
            value={formData.model}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />

          <TextInput
            label={'IP address'}
            placeholder={'Enter ip address'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, ip_address: value })}
            value={formData.ip_address}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
            regEx={IpV4RegEx}
          />

        </div>
        <div className={'add-camera-form-row-2'}>
          <TextInput
            label={'Streaming url'}
            placeholder={'Enter video url'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, streaming_url: value })}
            value={formData.streaming_url}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
          <Dropdown
            label={'Unit'}
            placeholder={'Select Unit'}
            width={'30%'}
            options={META_DATA.Unit}
            onSelect={(selection) => setFormData({ ...formData, unit: selection })}
            selection={formData.unit}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
          <TextInput
            label={'Location'}
            placeholder={'Enter Camera Location'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, location: value })}
            value={formData.location}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
          />
        </div>
        <div className={'add-camera-form-row-3'}>
          <LatLong
            label={'Lat, Long'}
            placeholder={'Enter latitude, longitude'}
            width={'30%'}
            onChange={(value) => setFormData({ ...formData, coordinates: value })}
            value={formData.coordinates}
          />
          <Resolution
              label={'Width, Height'}
              placeholder={'Enter Width, Height'}
              width={'30%'}
              onChange={(value) => setFormData({ ...formData, resolution: value })}
              value={formData.resolution}
              isRequired={false}
              isValid={validateFields}
              showWarning={submitClicked}
            />
        </div>

        {/* <div className={'add-camera-form-row-4'}>

          <Dropdown
            label={'Gate number'}
            placeholder={'Select gate'}
            width={'30%'}
            options={getGates()}
            onSelect={(value) => setFormData({ ...formData, gate_numbers: value })}
            selection={formData.gate_numbers}
            isRequired={true}
            isValid={validateFields}
            showWarning={submitClicked}
            variant={DropDownVariant.multiValue}
          />

          <TextArea
            label={'Alert Message'}
            placeholder={'Enter alert message..'}
            width={'25rem'}
            height={'6rem'}
            key={'RegisterNewVisitor-AlertMessage'}
            onChange={(value) => setFormData({ ...formData, message: value })}
            value={formData.message}
            maxLength={300}
          />
        </div> */}

        <div className={'btn-position'}>
          <Button
          class='btn-container'
           bgcolor={'#4D4D33'}
           text={'Save and Next'}
           textcolor={'white'}
           width={''}
           onClick={()=>{
             if (invalidFields.length) {
               toast.warning('Please fill all the required fields!');
               setSubmitClicked(true);
               return;
             }
             else 
               setShowSettings(true)
           }}
          />
        </div>
        {/* <AddCamSuccess
            open={pageVariables.successPopupOpen}
            close={handleClick}
          /> */}
        </div> 
        :
        <div className={'algo-settings-container'}>
          <span style={{fontSize:'0.8rem', color:'red', display:'block', marginBottom:'0.5rem'}}>Please tick the checkbox before entering the values *</span>
          <table>
            <thead>
              {algo_settings_columns.map((column, index) => {
                return(
                  <th style={[4].includes(index) ? { width: '40%' } : [5].includes(index) ? {width:'20%'} : {}}>
                    {column}
                  </th>
                )
              }
              )}
            </thead>
            <tbody>
              {Object.keys(algo_settings).map((setting, index) => {
                return(
                  <tr>
                    {algo_settings[setting].map(field => {
                      return(
                        <td>{getInputFields(field, setting)}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div style={{display:'flex', gap:'3rem', marginTop:'1rem'}}>
            {/* <div>
              <Switch
              value={settingsSwitchData.virtual_fence}
              onChange={(value) => 
                {
                  setSettingsSwitchData({
                    ...settingsSwitchData,
                    virtual_fence: value
                  });
                }}
              /> 
              
              Virtual Fence
            </div> */}
            <div>
              <Switch
                style={{margin: '1rem'}}
                value={settingsSwitchData.is_enabled}
                onChange={(value) => 
                  {
                    setSettingsSwitchData({
                      ...settingsSwitchData,
                      is_enabled: value
                    });
                  }}
              /> 
              Enable AI Algorithm
              </div>
          </div>
          <div className={'btn-position'}>
            <Button
            class='medium-btn-container'
              bgcolor={'white'}
              text={'Back'}
              textcolor={'grey'}
              width={'fit-content'}
              onClick={()=>setShowSettings(false)}
            />
            <Button
            class='btn-container'
              bgcolor={'#4D4D33'}
              text={'Update camera'}
              textcolor={'white'}
              width={''}
              onClick={()=>{
                handleClick()
              }}
            />
          </div>
        </div>}
      </div>
    );
  };
