import * as React from 'react';
import { Button } from '../Button/Button';
import './file-upload.scss';
import { uploadFile } from '../../../API/FileUpload';
import { useState } from 'react';
import { toast } from 'sonner';
import { CameraCapture } from '../CameraCapture/CameraCapture';

export interface IFileUploadProps {
    label: string;
    width: string;
    onUpload?: (file_url: string) => void
    isRequired?: boolean;
    url?: string;
    isValid?: (valid: boolean, field: string) => void;
    showWarning?: boolean;
    accept: UploadType;
    mazSizein?: number;
}

export enum UploadType {
  image = ".jpeg, .png, .jpg",
  file = ".pdf .text .txt .csv .jpeg, .png, .jpg", 
  fileAndImage = ".pdf .text .txt .csv .jpeg, .png, .jpg .jpeg, .png, .jpg"
}

export function FileUpload (props: IFileUploadProps) {
  const [fileSelect, setFileSelect] = React.useState<any>();
  const [uploading, setUploading] = useState(false);
  const [pageVars, setPageVars] = useState({
    openCamera: false,
    largeFileSelected: false
  })

  {/*
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    };
  */}

    const handleFileUpload = async (e) => {
      try {
        const file = e.target.files[0];
        if(props.mazSizein) {
          if(file.size > props.mazSizein * 1000000) {
            setPageVars({
              ...pageVars,
              largeFileSelected: true
            })
            return;
          }
          else {
            setPageVars({
              ...pageVars,
              largeFileSelected: false
            })
          }
        }
        setFileSelect(file);
      }
      catch(err) {
        toast.error("Error: failed to select file!");
      }
    };

    const validate = () => {
      if(props.isRequired && !props.url) {
        return false;
      }
      return true;
    }

    const uploadFromCamera = async (file) => {
      try {
        if(!file) return;

        setUploading(true);
        setPageVars({...pageVars, openCamera: false});
        let formData = new FormData();
        formData.append("file", file, file.name);
        const response = await uploadFile(formData);
        if(response) {
          props.onUpload(response.url);
          toast.success("Success: file uploaded!");
          setUploading(false);
        }
        else throw new Error("Error");
      }
      catch(err) {
        toast.error("Error: failed to upload file!");
        setUploading(false);
      }
    }

    const upload = async () => {
      try {
        if(!fileSelect && !pageVars.largeFileSelected ) return;
        
        setUploading(true);
        let formData = new FormData();
        formData.append("file", fileSelect, fileSelect.name);
        const response = await uploadFile(formData);
        if(response && response?.url) {
          props.onUpload(response.url);
          toast.success("Success: file uploaded!");
          setUploading(false);
          setFileSelect('');
        }
        else throw new Error("Error");
      }
      catch(err) {
        toast.error("Error: failed to upload file!");
        setUploading(false);
      }
    }

    const clear = () => {
      if(!fileSelect) return;

      setFileSelect(null);
    }
    
    React.useEffect(()=>{
    
      if(props.isValid) {
        if(validate()) props.isValid(true, props.label);
        else props.isValid(false, props.label);
      }
  
    },[fileSelect]);
  return (
    <div>
    <div style={{width: props.width}} className={'file-uploader-container'}>
        <div className={'file-uploader-label'}>
            {props.label} {props.isRequired && <span style={{color:'red'}}>*</span>}
        </div>
        {fileSelect && !props.url && <span style={{color:'red', fontSize:'0.8rem'}}>Please click on upload button to upload selected file!</span>}
        <div className={'file-uploader-section-parent-div '}
            style={{width:'100%', border: `${props.showWarning && !validate() ? '0.01rem solid red' : ''}`}}
        >
          <div className={'file-uploader-section'}>
           
            <img 
              style={{marginRight:'0.0rem'}}
              src={'/images/generic/upload.svg'}/>
            <input
                type="file"
                name="myFile"
                onChange={(e) => handleFileUpload(e)}
                value={fileSelect ? fileSelect.filename : ''}
                accept={props.accept}
            />
            </div>
            <div style={{display:'flex', marginTop:'1em'}}>
            <Button
            class='medium-btn-container'
                bgcolor={fileSelect ? '#4D4D33' : 'silver'}
                textcolor={'white'}
                width={'fit-content'}
                text={'Upload'}
                onClick={upload}
            />
            <Button
            class='medium-btn-container'
                bgcolor={'none'}
                textcolor={fileSelect ? '#4D4D33' : 'silver'}
                width={'fit-content'}
                text={'Clear'}
                onClick={clear}
            />

          {props?.url &&
            <div style={{display:'flex'}}>
              <img 
                style={{margin: 'auto', height:'2rem'}}
                src={'/images/generic/tick.svg'} 
                alt={'tick'}
              />
            </div>
          }     
          </div>
          {(props.accept && props.accept === UploadType.image) &&
          <div className={'capture-from-camera'}>
            <div style={{textAlign:'center'}}>or</div>
            <div 
              className={'camera-btn'} 
              onClick={()=>setPageVars({...pageVars, openCamera: true})}
            >
              <img src={'/images/generic/camera.svg'} alt={'camera'}/> 
              <span style={{height:'fit-content', width:'fit-content', margin:'auto', display:'block'}}>CAPTURE</span>
            </div>
          </div>
          }
        </div>
        {pageVars.openCamera &&
          <CameraCapture 
            upload={(file)=>{uploadFromCamera(file)}}
            closeCamera={()=>setPageVars({...pageVars, openCamera: false})}
          />
        }
    </div>
      <span style={{fontSize:'0.8em', marginTop:'-0.5rem', color:`${pageVars.largeFileSelected ? 'red' : 'gray'}`}}>
        {props.mazSizein ? `max file size ${props.mazSizein} MB!` : ''}
      </span>
    </div>
  );
}
