import { API_METHOD, MASTER_BASE_URL, FetchMaster } from "./FetchMaster";
import axios from 'axios';
import { LOCAL_STORAGE_KEYS } from "../components/Login/Login";

const handleSubmit = async (formData) => {
  try {
  var requestOptions = {
    method: 'POST',
    body: formData,
    headers: {
      token: `${localStorage.getItem(LOCAL_STORAGE_KEYS.stored_token)}`
    },
  };
    const resp = await fetch( MASTER_BASE_URL +"/file/uploads", requestOptions)
    .then(response => response.json())
    .then(result => {
      return result;
    })
    .catch((err) => {
      throw new Error(err);
    });
    return resp;
  }
  catch(err) {
    return {message: 'Failed to upload file!'}
  }
};
export const uploadFile = (formData) => {
    let url = `${MASTER_BASE_URL}/file/uploads`;
    return handleSubmit(formData);
}