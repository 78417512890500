import * as React from 'react';
import './preview.scss';
import { MASTER, MASTER_BASE_URL, SLAVE_BASE_URL } from '../../../API/FetchMaster';

interface IPreviewProps {
    previewImage: string;
    isOpen: boolean;
    onClose: () => void;
}

export const Preview = (props: IPreviewProps) => {
    return (
        <>{props.isOpen &&
            <div className={'preview-container'}>
                <div style={{ color: 'white', display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={'/images/notifications/closewhite.svg'}
                        alt={'close.svg'}
                        onClick={() => props.onClose()}
                    />
                </div>
                <div className={'preview-image-container'}>
                    <img
                            src={props.previewImage  != null ? ((MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL) + props.previewImage) : '/images/login/profileLogo.svg'}
                            alt={'No Preview'}
                    />
                </div>
            </div>
            }
        </>
    );
};
