import { API_METHOD, MASTER_BASE_URL, FetchMaster } from "./FetchMaster";
import { prepareUrl } from "./Visitors";

export const getNotifications = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/notifications?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}


export const getNewNotifications = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/notifications/new?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const createNotifications = (payload) => {
    let url = `${MASTER_BASE_URL}/notifications`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const acknowledgeNotifications = (payload, notification_id) => {
    let url = `${MASTER_BASE_URL}/notifications/${notification_id}/acknowledgements`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const fetchAcknowledgements = (notification_id) => {
    let url = `${MASTER_BASE_URL}/notifications/${notification_id}/acknowledgements?notification_id=${notification_id}`;
    return FetchMaster(url, API_METHOD.GET);
}

export const seenNotification = (notification_id, gate_number) => {
    let url = `${MASTER_BASE_URL}/notifications/read?notification_id=${notification_id}&&gate_number=${gate_number}`;
    return FetchMaster(url, API_METHOD.GET);
}