import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../Generic/Dropdown/Dropdown';
import { FileUpload, UploadType } from '../../Generic/FileUpload/FileUpload';
import { TextArea } from '../../Generic/TextArea/TextArea';
import TextInput from '../../Generic/TextInput/TextInput';
import MultiTextInput from '../../Generic/MultiTextInput/MultiTextInput';
import { createVisitor } from '../../../API/Visitors';
import './register-new-visitor.scss';
import { toast } from 'sonner';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { useContext } from 'react';

export interface IRegisterNewVisitorProps {
    showVisitorListPage: () => void
}

export function RegisterNewVisitor(props: IRegisterNewVisitorProps) {
    const { globalState: { user } } = useContext(GlobalStateContext);
    const [META_DATA, setMETA_DATA] = useState(user.application_configs);

    const form = {
        salutation: null,
        fullname: null,
        phone_number: null,
        gender: null,
        unit: null,
        category: null,
        vehicle_number: null,
        address: null,
        is_active: true,
        is_deleted: false,
        is_primary: true,
        profile_image: null,
        id_card: null,
    };

    const [invalidFields, setInvalidFields] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);

    const [formData, setFormData] = useState({ ...form });

    const validateFields = React.useCallback(async (valid: boolean, field: string) => {
        if (valid) {
            if (invalidFields.includes(field))
                setInvalidFields(Fields => {
                    let filtered = Fields.filter(value => value !== field);
                    return filtered;
                });
        }
        else {
            if (!invalidFields.includes(field))
                setInvalidFields(Fields => {
                    Fields.push(field);
                    return Fields;
                });
        }
    }, []);

    const submitForm = async () => {
        try {
            if (invalidFields.length) {
                toast.warning('Please fill all the required fields!');
                setSubmitClicked(true);
                return;
            }
            const response = await createVisitor({ ...formData });
            if (response && response.statusCode === 200) {
                toast.success('Success: new visitor created!');
                props.showVisitorListPage();
            }
            else throw new Error("Error");

        }
        catch (err) {
            toast.error('Error: failed to create visitor')
        }
    }

    return (
        <div className={'register-new-visitor-container'}>
            <div className={'register-new-visitor-title'}>
                Register New Visitor
            </div>

            <div className={'register-new-visitor-form-row-1'}>

                <TextInput
                    label={'Salutation'}
                    placeholder={'Enter visitor salutation'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-salutation'}
                    onChange={(value) => setFormData({ ...formData, salutation: value })}
                    value={formData.salutation}
                    isRequired={false}
                    // isValid={validateFields}
                    // showWarning={submitClicked}
                />
                <TextInput
                    label={'Name'}
                    placeholder={'Enter visitor name'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-name'}
                    onChange={(value) => setFormData({ ...formData, fullname: value })}
                    value={formData.fullname}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                />
                <TextInput
                    label={'Phone/ID no.'}
                    placeholder={'Enter visitor phone/id no.'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-Phone'}
                    onChange={(value) => setFormData({ ...formData, phone_number: value })}
                    value={formData.phone_number}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                    maxLength={10}
                    isNumber={true}
                />

            </div>
            <div className={'register-new-visitor-form-row-2'}>
                <Dropdown
                    label={'Gender'}
                    placeholder={'Select gender'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-Gender'}
                    options={META_DATA.Gender}
                    onSelect={(selection) => setFormData({ ...formData, gender: selection })}
                    selection={formData.gender}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                />


                <Dropdown
                    label={'Category'}
                    placeholder={'Select category'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-category'}
                    options={META_DATA.Category}
                    onSelect={(selection) => setFormData({ ...formData, category: selection })}
                    selection={formData.category}
                    isRequired={true}
                    isValid={validateFields}
                    showWarning={submitClicked}
                    variant={DropDownVariant.singleValue}
                />

                <TextInput
                    label={'Unit/Org'}
                    placeholder={'Enter org/unit name'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-unit'}
                    onChange={(value) => setFormData({ ...formData, unit: value })}
                    value={formData.unit}
                    isRequired={false}
                    // isValid={validateFields}
                    // showWarning={submitClicked}
                />

            </div>
            <div className={'register-new-visitor-form-row-text-area'}>
                <MultiTextInput
                    label={'Vehicle number'}
                    placeholder={'Enter vehicle number'}
                    width={'25rem'}
                    key={'RegisterNewVisitor-Other'}
                    onChange={(value) => setFormData({ ...formData, vehicle_number: value })}
                    value={formData.vehicle_number}
                    isRequired={false}
                    // isValid={validateFields}
                    // showWarning={submitClicked}
                />

            </div>
         
                <div className={'register-new-visitor-form-row-4'}>
                <TextArea
                    label={'Address'}
                    placeholder={'Enter visitors address..'}
                    width={'25rem'}
                    height={'6rem'}
                    key={'RegisterNewVisitor-Address'}
                    onChange={(value) => setFormData({ ...formData, address: value })}
                    value={formData.address}
                    isRequired={false}
                    // isValid={validateFields}
                    // showWarning={submitClicked}
                    maxLength={300}
                />

                    <FileUpload
                        label={'Upload profile photo'}
                        width={'25rem'}
                        key={'RegisterNewVisitor-upload-profile'}
                        onUpload={(file_url) => setFormData({ ...formData, profile_image: file_url })}
                        showWarning={submitClicked}
                        accept={UploadType.image}
                        url={formData.profile_image}
                    />
                    <FileUpload
                        label={'Upload Id card'}
                        width={'25rem'}
                        key={'RegisterNewVisitor-upload-id'}
                        onUpload={(file_url) => setFormData({ ...formData, id_card: file_url })}
                        showWarning={submitClicked}
                        accept={UploadType.image}
                        url={formData.id_card}
                    />
                </div>
               

            <div className={'register-visitor-btn-place'}>
                <Button
                class='btn-container'
                    bgcolor={'#4D4D33'}
                    text={'Save the details'}
                    textcolor={'white'}
                    width={'fit-content'}
                    onClick={submitForm}

                />
            </div>
        </div>
    );
}
