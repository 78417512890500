import * as React from 'react';
import './notification-management.scss';
import { useEffect } from 'react';
import { NotificationList } from './NotificationList/NotificationList';
import { CreateNotification } from './CreateNotification/CreateNotification';
import { GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import CompanyComponent from '../Generic/CompanyLogo/CompanyComponents';

export interface INotificationManagementProps {
}

export const NotificationManagement = (props: INotificationManagementProps) => {
  const { globalState: { permissions } } = React.useContext(GlobalStateContext);

  const sidePanelRoutes = [
    {
      id: '#1',
      title: 'Notification list',
      component: <NotificationList />,
      accessPermission: 'NOTIFICATION_READ'
    },
    {
      id: '#2',
      title: '+ Add new notification',
      component: <CreateNotification moveToNotificationsList={
        () => setPageVariables({ ...pageVariables, selectedRoute: sidePanelRoutes[0] })
      } />,
      accessPermission: 'NOTIFICATION_ADD'
    }
  ];
  const [pageVariables, setPageVariables] = React.useState({
    selectedRoute: sidePanelRoutes[0]
  })

  useEffect(() => {
    document.title = 'Notifications'
  }, []);

  return (
    <div className={'notification-management-container'}>
      <div className={'visitor-side-panel-container'}>
        <div className={'visitor-side-panel-routes-container'}>
          {
            sidePanelRoutes.map((route, index) => {
              return [...permissions].includes(route.accessPermission) && (
                <div
                  className={`visitor-side-panel-route-box 
                      ${pageVariables.selectedRoute.id === route.id ?
                      'selected-visitor-side-panel-route-box' : ''}`}
                  onClick={() => setPageVariables({ ...pageVariables, selectedRoute: route })}
                >
                  <div
                    className={pageVariables.selectedRoute.id === route.id ? 'selected-side-route' : 'not-selected-side-route'}
                  >
                  </div>
                  <div className={pageVariables.selectedRoute.id === route.id ? 'selected-visitor-side-panel-route' : 'visitor-side-panel-route'}>
                    {route.title}
                  </div>

                </div>)
            })
          }

        </div>
        <div className={'critical-ai-log-bottom'}>
           <CompanyComponent/>
        </div>
      </div>
      <div className={'visitor-content-container'}>
        {pageVariables.selectedRoute.component}
      </div>
    </div>);
}
