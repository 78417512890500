import { API_METHOD, MASTER_BASE_URL, FetchMaster } from "./FetchMaster";
import { prepareUrl } from "./Visitors";

export const getCameras = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/cameras?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const addCamera = (payload) => {
    let url = `${MASTER_BASE_URL}/cameras`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const deleteCamera = (camera_id) => {
    let url = `${MASTER_BASE_URL}/cameras?camera_id=${camera_id}`;
    return FetchMaster(url, API_METHOD.DELETE);
}

export const fetchCameraById = (fetchOptions, camera_id) => {
    let url = `${MASTER_BASE_URL}/cameras/${camera_id}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const updateCameraById = (payload, camera_id) => {
    let url = `${MASTER_BASE_URL}/cameras/${camera_id}`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}