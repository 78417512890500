import React from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import { Button } from "../Button/Button";
import './camera-capture.scss';

interface ICameraCaptureProps {
    upload: (file)=>void;
    closeCamera: ()=>void;
}

export const CameraCapture = (props: ICameraCaptureProps) => {
    const [capturedImage, setCapturedImage] = useState('');
    const videoConstraints = {
        width: 800,
        height: 620,
        facingMode: "user"
      };

    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
    () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImage(imageSrc)
    },
    [webcamRef]
    );
    const captureAgain = () => {
        setCapturedImage('');
    }

    const upload = async () => {
        const file = await fetch(capturedImage)
                    .then(res => res.blob())
                    .then(blob => {
                        let new_file = new File([blob], "captured.jpeg",{ type: "image/jpeg" })
                        return new_file;
                    });

        if(file) {
            props.upload(file);
        }
    }
  return (
    <div className="camera-container">
        {!capturedImage ?
            <div style={{height: videoConstraints.height+30 , width: videoConstraints.width}}>
                <Webcam 
                // height={600} width={600}
                    ref={webcamRef}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                />
            </div>
                :
            <div style={{height: videoConstraints.height+30 , width: videoConstraints.width}}>
                <img style={{height: videoConstraints.height , width: videoConstraints.width}} src={capturedImage} alt='captured'/>
            </div>
        }
        {!capturedImage ?
        <div className={'captured-btns'}>
            <Button
            class='medium-btn-container'
                text={'CAPTURE'}
                bgcolor={'red'}
                textcolor={'white'}
                width="fit-content"
                onClick={capture}
            />
            <Button
            class='medium-btn-container'
                text={'Cancel'}
                bgcolor={'none'}
                textcolor={'white'}
                width="0"
                onClick={props.closeCamera}
            />
        </div>
        :
        <div className={'captured-btns'}>
            <Button
            class='medium-btn-container'
                text={'RETAKE'}
                bgcolor={'red'}
                textcolor={'white'}
                width="fit-content"
                onClick={captureAgain}
            />
            <Button
            class='medium-btn-container'
                text={'Upload'}
                bgcolor={'green'}
                textcolor={'white'}
                width="fit-content"
                onClick={(upload)}
            />
            <Button
            class='medium-btn-container'
                text={'Cancel'}
                bgcolor={'none'}
                textcolor={'white'}
                width="0"
                onClick={props.closeCamera}
            />
        </div>
        }
    </div>
  );
};
