import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContext/GlobalContextProvider';
import { ACTIONS } from '../../GlobalContext/GlobalReducer';
import { Button } from '../Generic/Button/Button';
import './notification-ack-pop.scss';
import { NotificationBox } from './NotificationBox';

interface INotificationAcknowledgementProps {

}

export const NotificationAcknowledgement = (props: INotificationAcknowledgementProps) => {
    const {globalState:{notifications, popNotificationAlert, user}} = React.useContext(GlobalStateContext);
    const [popVariables, setPopVariables] = useState({
        responseClicked: false,
        message: ''
    });

    const [updatingQueue, setUpdatingQueue] = useState(false);

    const [notificationsQueue, setNotificationsQueue] = useState([]);

    const globalDispatcher = React.useContext(GlobalDispatchContext);

    const textToSpeech = async (newNotification) => {

        const synth = window.speechSynthesis;
        const voice1 = new SpeechSynthesisUtterance(`New notification received`);
        synth.speak(voice1);

        const voice2 = new SpeechSynthesisUtterance(`${newNotification.title}`);
        synth.speak(voice2);

        const voice3 = new SpeechSynthesisUtterance(`${newNotification.message}`);
        synth.speak(voice3);
    };

    const audible = async (newNotification) => {
        await textToSpeech(newNotification);
    }

    useEffect(()=>{
        if(notifications && notifications.length) {
            if(JSON.stringify(notifications) !== JSON.stringify(notificationsQueue)) {
                if(updatingQueue) return;
                audible(notifications[0]);
                setNotificationsQueue(notifications);
            }
        }
    },[notifications]);
  return(
      <div className={'notification-ack-pop-container-parent'}>
            {
                notificationsQueue.map((notification, index)=>{
                    return (
                        <div style={{
                            display:'flex',
                            marginTop:`${(index > (notificationsQueue.length - 5)) ? 0.7 : 0}rem`
                        }}>
                            <NotificationBox
                                notification={notificationsQueue[notificationsQueue.length - index - 1]}
                                remove={async (id)=>{
                                    setUpdatingQueue(true);
                                    let remainingNotifications = notificationsQueue.filter( notification => notification.id !== id );
                                    await setNotificationsQueue(remainingNotifications);
                                    if(remainingNotifications.length === 0) 
                                        globalDispatcher({type: ACTIONS.popNotification, booleanPayload: false});
                                    setUpdatingQueue(false);
                                }}
                            />
                        </div>
                    )
                })
            }
            {/* <Button
                bgcolor={'white'}
                text={'Test'}
                textcolor={'grey'}
                width={'fit-content'}
                onClick={testTextToSpeech}
            /> */}
      </div>
  ) ;
};
