import { API_METHOD, MASTER_BASE_URL, FetchMaster } from "./FetchMaster";
import { prepareUrl } from "./Visitors";

export const addGate = (payload) => {
    let url = `${MASTER_BASE_URL}/gates`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const addGateCred = (payload) => {
    let url = `${MASTER_BASE_URL}/gates/creds`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const addNewCred = (payload) => {
    let url = `${MASTER_BASE_URL}/auth/register`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const getAllGates = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/gates?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const getGateById = (gate) => {
    let url = `${MASTER_BASE_URL}/gates/details?gate_number=${gate}`;
    return FetchMaster(url, API_METHOD.GET);
}

export const updateGate = (gate_id, payload) => {
    let url = `${MASTER_BASE_URL}/gates/${gate_id}`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}

export const disableGate = (gate_id, block) => {
    let url = `${MASTER_BASE_URL}/gates/${gate_id}?block=${block}`;
    return FetchMaster(url, API_METHOD.DELETE);
}