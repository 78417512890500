import * as React from 'react';
import './loader.scss';

interface ILoaderProps {
    loadingText: string;
    height?: string;
}

export const Loader = (props: ILoaderProps) => {
  return (
      <div className={'loader-container'} style={ props.height ? {height:props.height} :{height:'30vh'}}>
          <div className={'loading-message'}>
              {props.loadingText}...
          </div>
      </div>
  )
};

export default Loader;
