import React, { useState } from 'react';
import './Pagination.scss';

const Pagination = ({ currentPage, totalItems, initialLimit, onPageChange, onLimitChange }) => {
  const [limit, setLimit] = useState(initialLimit);

  const totalPages = Math.ceil(totalItems / limit);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleLimitChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setLimit(newLimit);
    onLimitChange(newLimit);
    // setCurrentPage(1); // Reset to first page whenever limit changes
    onPageChange(1);
  };

  return (
    <div className="pagination-container">
      <button 
        className="pagination-button" 
        onClick={handlePreviousPage} 
        disabled={currentPage === 1}
      >
        Previous Page
      </button>
      <span className="pagination-info">
        Page {currentPage} of {totalPages}
      </span>
      <button 
        className="pagination-button" 
        onClick={handleNextPage} 
        disabled={currentPage === totalPages}
      >
        Next Page
      </button>
      <label className="pagination-limit">
        Items per page:
        <select value={limit} onChange={handleLimitChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
        </select>
      </label>
    </div>
  );
};

export default Pagination;
