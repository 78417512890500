import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './text-input.scss';
export interface ITextInputProps {
    placeholder: string;
    label: string;
    width: string;
    value?: any;
    onChange?: (value: string) => void;
    disabled?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    isValid?: (valid: boolean, field: string) => void;
    showWarning?: boolean;
    isNumber?: boolean;
    regEx?: RegExp;
}

const TextInput = (props: ITextInputProps) => {
  const [inputValue, setInputValue] = useState<any>();
  const validate = () => {
    if(props.isRequired && (!inputValue && !props.value)) {
      return false;
    }
    else if(props.regEx && inputValue) {
      if(!new RegExp(props.regEx).test(inputValue)) {
        return false; 
      }
    }
    return true;
  }

  useEffect(()=>{
    
    if(props.isValid) {
      if(validate() && (inputValue || props.value)) props.isValid(true, props.label);
      else props.isValid(false, props.label);
    }

  },[inputValue, props.value]);

  return (
    <div style={{width: props.width}} className={'text-input-container'}>
      <div className={'text-input-label'}>
          {props.label} {props.isRequired && <span style={{color:'red'}}>*</span>}
      </div>
      <div className={'text-input-form'} style={{border: `${ (props.showWarning || inputValue) && !validate() ? '0.01rem solid red' : ''}`}}>
            <input 
                type={'text'}
                placeholder={props.placeholder}
                onChange={(event) => {
                  let value = event.target.value;

                  if(props.isNumber) {
                    value= value.replace(/[^0-9]/g, '');
                  }

                  if(props.maxLength) {
                    if( (!inputValue || (inputValue.length < props.maxLength)) || value.length <= props.maxLength)  {
                      props.onChange(value);
                      setInputValue(value);
                    }

                  }
                  else {
                    props.onChange(value);
                    setInputValue(value);
                  }
                  

                }}
                value={props.value}
                disabled={props.disabled}
            />
      </div>
    </div>
  );
}

export default TextInput;