import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../Generic/Button/Button';
import Dropdown, { DropDownVariant } from '../../Generic/Dropdown/Dropdown';
import { FileUpload, UploadType } from '../../Generic/FileUpload/FileUpload';
import { TextArea } from '../../Generic/TextArea/TextArea';
import TextInput from '../../Generic/TextInput/TextInput';
import './create-new-notification.scss';
import { createNotifications } from '../../../API/Notifications';
import { useContext } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { toast } from 'sonner';

interface ICreateNotificationProps {
  moveToNotificationsList: () => void;
}

export const CreateNotification = (props: ICreateNotificationProps) => {
  const { globalState: { user, gates } } = useContext(GlobalStateContext);
  const [META_DATA, setMETA_DATA] = useState(user?.application_configs);

  const getGates = () => {
    let gateNumbers = [];
    gates && gates.forEach(gate => {
      gateNumbers.push(gate.gate_number);
    });

    return gateNumbers;
  }

  const form = {
    title: null,
    group: null,
    type: null,
    message: null,
    file: null,
    gate_numbers: null,
    created_by: null,
  }
  const [formData, setFormData] = useState({
    ...form
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const validateFields = React.useCallback(async (valid: boolean, field: string) => {
    if (valid) {
      if (invalidFields.includes(field))
        setInvalidFields(Fields => {
          let filtered = Fields.filter(value => value !== field);
          return filtered;
        });
    }
    else {
      if (!invalidFields.includes(field))
        setInvalidFields(Fields => {
          Fields.push(field);
          return Fields;
        });
    }
  }, []);

  const createNewNotification = async () => {
    try {
      if (invalidFields.length) {
        toast.warning('Please fill all the required fields!');
        setSubmitClicked(true);
        return;
      }
      const payload = {
        ...formData,
        created_by: user?.username,
        gate_numbers: formData.gate_numbers?.split(",")
      }
      const response = await createNotifications(payload);

      if (response && response.statusCode === 200) {
        setFormData(form);
        toast.success("Success: notification created!");
        props.moveToNotificationsList();
      }
      else throw new Error("Error");

    }
    catch (err) {
      toast.error("Error: failed to create notification!");
    }
  }

  return (
    <div className={'new-notification-form-container'}>
      <div className={'title-left'}>
        Create new notification
      </div>
      <div className={'new-notification-form-row-1'}>
        <TextInput
          label={'Title'}
          placeholder={'Enter title'}
          width={'28%'}
          onChange={(value) => {
            setFormData({ ...formData, title: value })
          }}
          value={formData.title}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
        />
        
        <Dropdown
          label={'Gate number'}
          placeholder={'Select gate'}
          width={'30%'}
          options={getGates()}
          onSelect={(value) => setFormData({ ...formData, gate_numbers: value })}
          selection={formData.gate_numbers}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          variant={DropDownVariant.multiValue}
        />

        <Dropdown
          label={'Type'}
          placeholder={'Select type'}
          width={'28%'}
          options={META_DATA.NotificationType}
          onSelect={(selection) => {
            setFormData({ ...formData, type: selection })
          }}
          selection={formData.type}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
        />
      </div>
      <div className={'new-notification-form-row-2'}>
        <TextArea
          label={'Message'}
          placeholder={'Enter message or info'}
          width={'26%'}
          height={'5rem'}
          onChange={(value) => {
            setFormData({ ...formData, message: value })
          }}
          value={formData.message}
          isRequired={true}
          isValid={validateFields}
          showWarning={submitClicked}
          maxLength={300}
        />
      </div>
      <div>
        <FileUpload
          label={'Upload file'}
          width={'30%'}
          accept={UploadType.file}
          onUpload={(file_url) => setFormData({ ...formData, file: file_url })}
          url={formData.file}
          mazSizein={3}
        />
      </div>
      <div className={'new-notification-btn'}>
        <Button
        class='btn-container'
          text={'Send Notification'}
          bgcolor={'#4D4D33'}
          textcolor={'white'}
          width={'fit-content'}
          onClick={createNewNotification}
        />
      </div>
    </div>
  );
};
