import * as React from "react";
import { globalStateReducer } from "./GlobalReducer";

export interface IGlobalState {
    loggedIn: true | false;
    entryExitVisitor: any;
    user: any;
    permissions: any;
    gates: any;
    company: any;
    syncNotifications: boolean;
    notifications: any;
    popNotificationAlert: boolean;
}
export const initialGlobalState: IGlobalState = {
    loggedIn: false,
    entryExitVisitor: null,
    user: null,
    permissions: [],
    gates: [],
    company: null,
    syncNotifications: false,
    notifications: [],
    popNotificationAlert: false
  };

const initialStateContext = {
    globalState: initialGlobalState
};

const initialDispatchContext: any = null;

export const GlobalStateContext = React.createContext(initialStateContext);
export const GlobalDispatchContext = React.createContext(initialDispatchContext);

export const GlobalContextProvider = ({children}) => {
    const [globalState, dispatch] = React.useReducer(globalStateReducer, initialGlobalState);
    return (
    <GlobalStateContext.Provider value={{globalState}}>
        <GlobalDispatchContext.Provider value={dispatch}>
            {children}
        </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
    )
}



