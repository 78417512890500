import { API_METHOD, MASTER_BASE_URL, FetchMaster } from "./FetchMaster";
import { prepareUrl } from "./Visitors";

export const getunits = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/configs/units?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const getusers = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/users?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const getUser = (id) => {
    let url = `${MASTER_BASE_URL}/users/${id}`;
    return FetchMaster(url, API_METHOD.GET);
}

export const updateUser = (payload) => {
    let url = `${MASTER_BASE_URL}/users`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}



export const addUnit = (payload) => {
    let url = `${MASTER_BASE_URL}/configs/units`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const updateUnit = (id, payload) => {
    let url = `${MASTER_BASE_URL}/configs/units/${id}`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}



export const deleteUnit = (id) => {
    let url = `${MASTER_BASE_URL}/configs/units/${id}`;
    return FetchMaster(url, API_METHOD.DELETE);
}


export const getUnit = (id) => {
    console.log(id)
    let url = `${MASTER_BASE_URL}/configs/units/${id}`;
    return FetchMaster(url, API_METHOD.GET);
}


export const getCategories = (fetchOptions) => {
    let url = `${MASTER_BASE_URL}/configs/categories?currentPage=${fetchOptions.page}`;
    const fetchUrl = prepareUrl(url, fetchOptions);
    return FetchMaster(fetchUrl, API_METHOD.GET);
}

export const addCategory = (payload) => {
    let url = `${MASTER_BASE_URL}/configs/categories`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}

export const updateCategory = (id, payload) => {
    let url = `${MASTER_BASE_URL}/configs/categories/${id}`;
    return FetchMaster(url, API_METHOD.PUT, JSON.stringify(payload));
}


export const deleteCategory = (id) => {
    let url = `${MASTER_BASE_URL}/configs/categories/${id}`;
    return FetchMaster(url, API_METHOD.DELETE);
}

export const getCategory = (id) => {
    console.log(id)
    let url = `${MASTER_BASE_URL}/configs/categories/${id}`;
    return FetchMaster(url, API_METHOD.GET);
}



export const addPermissions = (payload) => {
    let url = `${MASTER_BASE_URL}/configs/categories`;
    return FetchMaster(url, API_METHOD.POST, JSON.stringify(payload));
}
