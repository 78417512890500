import * as React from 'react';
import './search-bar.scss';

export interface ISearchBarProps {
    placeholder: string;
    width: string;
    onSearch: (query) => void;
}

export const SearchBar = (props: ISearchBarProps) => {

  const search = (query) => {
    props.onSearch(query);
  }

  const debounce = (func, wait) => {
    let timeout;
  
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const debouncedFetchSearchResults = debounce(search, 1500);

  return (
    <div className={'search-bar-container'}>
        <img src={'/images/generic/mag-glass.svg'} alt={'mag-glass'}/>
        <input 
          style={{width: props.width}} 
          type='text' 
          placeholder={props.placeholder}
          onChange={
            (_event) => {
              debouncedFetchSearchResults(_event.target.value);
            }
          }
        />
    </div>
  );
}
