import { LOCAL_STORAGE_KEYS } from "../components/Login/Login";

// export const BASE_URL = process.env.BACKEND_HOST  ;
// console.log(BASE_URL)



export const MASTER_BASE_URL = 'https://www.critical.bytecoder.in/backend';
export const SLAVE_BASE_URL = 'http://13.232.164.107:5000';
export const MASTER = true

// https://192.168.1.253/

// export const MASTER = false
// export const MASTER_BASE_URL = 'https://192.168.1.253/backend';
// export const SLAVE_BASE_URL = 'http://localhost:8000';

// export const MASTER = true 
// export const MASTER_BASE_URL = 'https://192.168.1.253/backend';
// export const SLAVE_BASE_URL = 'http://localhost:5000';

// export const MASTER = true
// export const MASTER_BASE_URL = 'http://localhost:5000';
// export const SLAVE_BASE_URL = 'http://localhost:5000';

export enum API_METHOD {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT'
}

export const FetchMaster = async (url: string, method: string, body?, removeContentType?) => {
  
    try {
        return await fetch(url, {
            method: method,
            headers: {
              'Content-type': removeContentType ? undefined : 'application/json',
              Accept: 'application/json',
              token: `${localStorage.getItem(LOCAL_STORAGE_KEYS.stored_token)}`
            },
            body: body,
          })
            .then((response) => response.json())
            .then((response) => {
              if([200, 201].includes(response.statusCode)){
                return response;
              }
              else throw new Error(response.message);
            })
            .catch((err) => {
              throw new Error(err);
              
            });
      
    }
    catch(err) {
      return { success: false, statusCode: 500, message: err.message};
    }
}
