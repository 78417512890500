import * as React from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { GlobalStateContext } from '../../../GlobalContext/GlobalContextProvider';
import { Button } from '../Button/Button';
import './filters.scss';

export interface IFiltersProps {
    apply: (filters) => void;
    unitFilter?: boolean;
    categoryFilter?: boolean;
    activityFilter?: boolean;
    gateFilter?: boolean;
    dateFilter?: boolean;
}

export const Filters = (props: IFiltersProps) => {
    const { globalState: { user, gates } } = useContext(GlobalStateContext);
    const [META_DATA, setMETA_DATA] = useState(user.application_configs);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    const dateToday = yyyy + '-' + mm + '-' + dd;

    const defaultFilters = {
        start_date: '',
        end_date: '',
        unit: '',
        category: '',
        activity: '',
        gate_number: ''
    }
    const [filters, setFilters] = useState(defaultFilters);

    const [filterVars, setFilterVars] = useState({
        showFilters: false
    });
    const filterRef = useRef(null);
    const filterButtonRef = useRef(null);

    const findOutsideClick = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setFilterVars({ ...filterVars, showFilters: false });
        }
    }

    const filterActive = () => {
        const find = Object.keys(filters).find(key => filters[key] !== '');
        if (find) return true;
        else return false;
    }

    const applyFilter = () => {
        if (filterActive()) {
            props.apply(filters);
            setFilterVars({ ...filterVars, showFilters: !filterVars.showFilters })
        }
    }
    const clear = () => {
        if (filterActive()) {
            setFilters(defaultFilters);
            props.apply(null);
        }
    }

    React.useEffect(() => {
        document.addEventListener("mousedown", findOutsideClick);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", findOutsideClick);
        };
    }, [filterRef]);

    return (
        <div className={'filter-container'}>
            <div>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFilterVars({ ...filterVars, showFilters: !filterVars.showFilters })}
                    ref={filterButtonRef}
                >
                    <img src={'/images/generic/Filters.svg'} />
                </div>
            </div>

            {filterVars.showFilters &&
                <div className={'filter-pop-over-container'} ref={filterRef}>
                    {props.dateFilter &&
                        <>
                            <div className={'filter-label'}>
                                Date-Time Range
                            </div>
                            <div className={'date-range'}>
                                <span>From</span>
                                <input
                                    type='date'
                                    onChange={(event) => {
                                        setFilters({
                                            ...filters,
                                            start_date: event.target.value
                                        })
                                    }}
                                    value={filters.start_date}
                                    max={dateToday}
                                />
                                <span>to</span>
                                <input type='date'
                                    onChange={(event) => {
                                        setFilters({
                                            ...filters,
                                            end_date: event.target.value
                                        })
                                    }}
                                    value={filters.end_date}
                                    max={dateToday}
                                />
                            </div>
                        </>
                    }

                    {props.unitFilter &&
                        <>
                            <div className={'filter-label'}>
                                Unit
                            </div>
                            <div className={'filter-check-boxes'}>
                                {
                                    <select className={'filter-select'}
                                        onChange={(event) => {
                                            setFilters({
                                                ...filters,
                                                unit: event.target.value
                                            })
                                        }}
                                        value={filters.unit}
                                    >
                                        <option value={''}>Select Unit</option>
                                        {
                                            META_DATA && META_DATA.Unit &&
                                            META_DATA.Unit.map(unit => <option value={unit}>{unit}</option>)
                                        }
                                    </select>
                                }
                            </div>
                        </>
                    }
                    {props.categoryFilter &&
                        <>
                            <div className={'filter-label'}>
                                Category
                            </div>
                            <div className={'filter-check-boxes'}>
                                {
                                    <select className={'filter-select'}
                                        onChange={(event) => {
                                            setFilters({
                                                ...filters,
                                                category: event.target.value
                                            })
                                        }}
                                        value={filters.category}
                                    >
                                        <option value={''}>Select Category</option>
                                        {
                                            META_DATA && META_DATA.Category &&
                                            META_DATA.Category.map(Category => <option value={Category}>{Category}</option>)
                                        }
                                    </select>
                                }
                            </div>
                        </>}
                    {user && (user.role === "Admin") && props.gateFilter &&
                        <>
                            <div className={'filter-label'}>
                                Gate
                            </div>
                            <div>
                                <select className={'filter-select'}
                                    onChange={(event) => {
                                        setFilters({
                                            ...filters,
                                            gate_number: event.target.value
                                        })
                                    }}
                                    value={filters.gate_number}
                                >
                                    <option value={''}>Select gate no.</option>
                                    {
                                        gates && gates.map(gate => <option value={gate.gate_number}>{gate.gate_number}</option>)
                                    }
                                </select>
                            </div>
                        </>
                    }
                    {props.activityFilter &&
                        <>
                            <div className={'filter-label'}>
                                Activity Type
                            </div>
                            <div className={'filter-check-boxes'}>
                                {
                                    <select className={'filter-select'}
                                        onChange={(event) => {
                                            setFilters({
                                                ...filters,
                                                activity: event.target.value
                                            })
                                        }}
                                        value={filters.activity}
                                    >
                                        <option value={''}>Select Activity</option>
                                        {
                                            META_DATA && META_DATA.ActivityType &&
                                            META_DATA.ActivityType.map(ActivityType => <option value={ActivityType}>{ActivityType}</option>)
                                        }
                                    </select>
                                }
                            </div>
                        </>
                    }
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '0rem', marginTop: '2rem', marginRight: '-1rem' }}>
                        <Button
                            class='medium-btn-container'
                            text={'Apply'}
                            textcolor={'white'}
                            bgcolor={filterActive() ? '#4D4D33' : 'silver'}
                            width={'fit-content'}
                            onClick={applyFilter}
                        />
                        <Button
                            class='medium-btn-container'
                            text={'Clear'}
                            textcolor={filterActive() ? '#4D4D33' : 'silver'}
                            bgcolor={'none'}
                            width={'fit-content'}
                            onClick={clear}
                        />
                    </div>
                </div>}
        </div>
    );
}
