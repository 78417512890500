import * as React from 'react';
import { useContext } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from '../../../../GlobalContext/GlobalContextProvider';
import { ACTIONS } from '../../../../GlobalContext/GlobalReducer';
import { Button } from '../../../Generic/Button/Button';
import './profile-details-side-card.scss';
import { MASTER, MASTER_BASE_URL, SLAVE_BASE_URL } from '../../../../API/FetchMaster';
import { Preview } from '../../../Generic/ImagePreviewer/Preview';

export interface IProfileDetailsSideCardProps {
    profile: any;
    open: boolean;
    close: () => void;
    makeEntry: () => void;
    makeExit: () => void;
}

//profile object
{/*
    "visitor_id": visitor_id,
    "fullname" : fullname,
    "phone_number" : phone_number,
    "gender" : gender,
    "unit" : unit,
    "category" :  category,
    "vehicle_number" : vehicle_number,
    "View_details" :  'View Details',
    "is_primary": is_primary,
    "profile_image": profile_image,
    "id_card": id_card
*/}

export const ProfileDetailsSideCard = (props: IProfileDetailsSideCardProps) => {
    const [show, setShow] = React.useState(false);
    const globalDispatcher = useContext(GlobalDispatchContext);
    const { globalState: { permissions, user } } = useContext(GlobalStateContext);

    const [pageVars, setPageVars] = React.useState({
        acknowledgement: null,
        showImage: false
    })

    const [viewIdCard, setViewIdCard] = React.useState({
        acknowledgement: null,
        showImage: false
    })


    React.useEffect(() => {

        if (props.open) {
            setShow(true)
            setPageVars({ ...pageVars, showImage: true })

        }
        else{

        setShow(false);
        setPageVars({ ...pageVars, showImage: false })

        }
    }, [props.open]);

    const entryBtnClicked = () => {
        globalDispatcher({ type: ACTIONS.setEntryExitVisitor, payload: props.profile });
        props.makeEntry();
    }

    const exitBtnClicked = () => {
        globalDispatcher({ type: ACTIONS.setEntryExitVisitor, payload: props.profile });
        props.makeExit();
    }

    return (
        <div>{show &&
            <div className={'profile-details-pop-up-container'}>
                <div className={'close-btn'}>
                    <img
                        src={'/images/notifications/close.svg'}
                        alt={'close.svg'}
                        onClick={() => props.close()}
                    />
                </div>
                {/* <div className={'pop-up-title'}>
            {'Profile Details'}
          </div> */}
                <div style={{ display: 'flex', marginTop: '2rem' }}>
                    {/* <div className={'visitor-details-profile-image'}>
                        {<img
                            src={props.profile.profile_image ? ((MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL) + props.profile.profile_image) : '/images/login/profileLogo.svg'}
                        />}
                    </div> */}
                    <div className={'visitor-details-profile-title'}>
                        <div className={'visitor-details-profile-title-fullname'}>{props.profile.fullname}</div>
                    </div>
                </div>
                <div className={'visitor-details'}>
                    <div>
                        <div className={'pop-up-details'}>
                            <span>Visitor Id : </span>
                            <span>{props.profile.visitor_id}</span>
                        </div>
                        <div className={'pop-up-details'}>
                            <span>Status : </span>
                            <span className={`${[null, true].includes(props.profile.is_active) ? 'status-active' : 'status-blocked'}`}>
                                {[null, true].includes(props.profile.is_active) ? 'Active' : 'Blocked'}
                            </span>
                        </div>
                        <div className={'pop-up-details'}>
                            <span>Phone no./ID no. : </span>
                            <span>{props.profile.phone_number}</span>
                        </div>
                        <div className={'pop-up-details'}>
                            <span>Gender : </span>
                            <span>{props.profile.gender}</span>
                        </div>
                        <div className={'pop-up-details'}>
                            <span>category  : </span>
                            <span>{props.profile.category}</span>
                        </div>

                        <div className={'pop-up-details'}>
                            <span>Unit : </span>
                            <span>{props.profile.unit}</span>
                        </div>
                        <div className={'pop-up-details'}>
                            <span>Registered at : </span>
                            <span>{props.profile.created_at}</span>
                        </div>
                    </div>
                </div>

                <div className={'pop-up-file'}>
                    <div className={'pop-up-file-title'}>
                        Profile Photo
                    </div>
                    {props.profile.profile_image ?
                        <div className={'pop-up-file-container'}>
                            <div className={'pop-up-file-details'}>
                                <div className={'pop-up-file-img'}>
                                    <img
                                        src={(MASTER ? MASTER_BASE_URL : SLAVE_BASE_URL) + props.profile.profile_image || '/images/login/profileLogo.svg'}
                                        alt={'pdf.svg'}
                                    />
                                </div>

                            </div>
                            <div className={'pop-up-file-name-download'}>
                                <a href={'#'}
                                    onClick={() => {
                                        setPageVars({ ...pageVars, showImage: true })
                                    }}
                                    style={{ textDecoration: 'none' }}>
                                    view
                                </a>
                            </div>
                        </div> : 'N/A'}

                </div>


                {[...permissions].includes("ACTIVITY_ADD") &&
                    [null, true].includes(props.profile.is_active) && user.allowedCategories?.includes(props.profile.category) ?
                    <div className={'entry-exit-button'}>
                        <div className={'entry-button'}>
                            <Button
                                class='btn-container'
                                bgcolor={'#4D4D33'}
                                textcolor={'white'}
                                text={'Allow entry'}
                                width={'10rem'}
                                onClick={entryBtnClicked}
                            />
                        </div>
                        <div className={'entry-button'}>
                            <Button
                                class='btn-container'
                                bgcolor={'#7B0323'}
                                textcolor={'white'}
                                text={'Allow exit'}
                                width={'10rem'}
                                onClick={exitBtnClicked}
                            />
                        </div>
                    </div> :

                    ([...permissions].includes("ACTIVITY_ADD") && !user.allowedCategories?.includes(props.profile.category)) &&

                    <div className={'pop-up-file'}>
                        <div className={'pop-up-file-title'}>
                            Permission
                        </div>
                       <p style={{color:'rgb(123, 3, 35)'}}>{'This category is not allowed through this gate.'}</p> 

                    </div>

                }

            </div>

        }
            <Preview
                previewImage={`${props.profile?.profile_image}`}
                isOpen={pageVars.showImage}
                onClose={() => setPageVars({ ...pageVars, showImage: false })}
            />
            {/* <Preview
                previewImage={`${props.profile?.id_card}`}
                isOpen={viewIdCard.showImage}
                onClose={() => setViewIdCard({ ...pageVars, showImage: false })}
            /> */}
        </div>)
}
