import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './lat-long.scss';
export interface ITextInputProps {
    placeholder: string;
    label: string;
    width: string;
    value?: any;
    onChange?: (value: string) => void;
    disabled?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    isValid?: (valid: boolean, field: string) => void;
    showWarning?: boolean;
    isNumber?: boolean;

}

export const LatLong = (props: ITextInputProps) => {
  const [inputValue, setInputValue] = useState({
    latitude: null,
    longitude: null
  });
  const validate = () => {
    if(props.isRequired && (!inputValue && !props.value)) {
      return false;
    }
    return true;
  }

  useEffect(()=>{

    if(props.value) {
      let coordinates = props.value.split(",");
      if(coordinates.length === 2) {
        setInputValue({
          ...inputValue,
          latitude: coordinates[0],
          longitude: coordinates[1]
        })
      }
      else {
        setInputValue({
          ...inputValue,
          latitude: coordinates[0],
        })
      }
    }

  },[props.value]);

  const setValue = (value, type) => {
    switch (type) {
      case 'lat':
        if(value && inputValue.longitude) {
          let longitude = inputValue.longitude.toString();
          props.onChange(`${value},${longitude}`);
        }
        break;
      case 'long':
        if(inputValue.latitude && value) {
          let latitude = inputValue.latitude.toString();
          props.onChange(`${latitude},${value}`);
        }
        break;
      default:
        break;
    }
  }

  return (
    <div style={{width: props.width}} className={'latlong-input-container'}>
      <div className={'latlong-input-label'}>
          {props.label} {props.isRequired && <span style={{color:'red'}}>*</span>}
      </div>
      <div className={'text-input-form'} style={{border: `${ props.showWarning && !validate() ? '0.01rem solid red' : ''}`}}>
            <input 
                type={'text'}
                placeholder={'Latitude'}
                onChange={(event) => {
                  let value = event.target.value;

                  value = value.replace(/[^0-9+.-]/g, '');
                  
                  if(value) setInputValue({...inputValue, latitude: value})
                  setValue(value, 'lat')
                }}
                value={inputValue.latitude}
                disabled={props.disabled}
            />
            <input 
                type={'text'}
                placeholder={'Longitude'}
                onChange={(event) => {
                  let value = event.target.value;
                  value = value.replace(/[^0-9+.-]/g, '');
                  if(!value) setInputValue({...inputValue, longitude: ''})
                  setValue(value, 'long')
                  }}
                  
                value={inputValue.longitude}
                disabled={props.disabled}
            />
      </div>
      <span style={{fontSize:'0.7rem'}}>{'Please enter both latitude & longitude'}</span>

    </div>
  );
}



export const Resolution = (props: ITextInputProps) => {
  const [inputValue, setInputValue] = useState({
    latitude: null,
    longitude: null
  });
  const validate = () => {
    if(props.isRequired && (!inputValue && !props.value)) {
      return false;
    }
    return true;
  }

  useEffect(()=>{

    if(props.value) {
      console.log(props.value)
      let coordinates = props.value.split(",");
      if(coordinates.length === 2) {
        setInputValue({
          ...inputValue,
          latitude: coordinates[0],
          longitude: coordinates[1]
        })
      }
      else {
        setInputValue({
          ...inputValue,
          latitude: coordinates[0],
        })
      }
    }

  },[props.value]);

  const setValue = (value, type) => {
    switch (type) {
      case 'width':
        if(value && inputValue.longitude) {
          let longitude = inputValue.longitude.toString();
          props.onChange(`${value},${longitude}`);
        }
        break;
      case 'height':
        if(inputValue.latitude && value) {
          let latitude = inputValue.latitude.toString();
          props.onChange(`${latitude},${value}`);
        }
        break;
      default:
        break;
    }
  }

  return (
    <div style={{width: props.width}} className={'latlong-input-container'}>
      <div className={'latlong-input-label'}>
          {props.label} {props.isRequired && <span style={{color:'red'}}>*</span>}
      </div>
      <div className={'text-input-form'} style={{border: `${ props.showWarning && !validate() ? '0.01rem solid red' : ''}`}}>
            <input 
                type={'text'}
                placeholder={'Width'}
                onChange={(event) => {
                  let value = event.target.value;

                  value = value.replace(/[^0-9+.-]/g, '');
                  
                  if(value) setInputValue({...inputValue, latitude: value})
                  setValue(value, 'width')
                }}
                value={inputValue.latitude}
                disabled={props.disabled}
            />
            <input 
                type={'text'}
                placeholder={'Height'}
                onChange={(event) => {
                  let value = event.target.value;
                  value = value.replace(/[^0-9+.-]/g, '');
                  if(!value) setInputValue({...inputValue, longitude: ''})
                  setValue(value, 'height')
                  }}
                  
                value={inputValue.longitude}
                disabled={props.disabled}
            />
      </div>
      <span style={{fontSize:'0.7rem'}}>{'Please enter camera width & height'}</span>

    </div>
  );
}
